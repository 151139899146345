import { Section } from "@jfrk/react-heading-levels";
import cx from "classnames";
import React from "react";

import Article from "../components/Article";
import BoxNavigation from "../components/BoxNavigation";
import Breadcrumb from "../components/Breadcrumb";
import Layout from "../components/Layout";
import ModuleArea from "../components/ModuleArea/ModuleArea";
import SEO from "../components/seo";
import { getBreadcrumbsHidden } from "../hooks/header";
import { useHTMLProcessor } from "../hooks/htmlProcessor";
import { PageContextProvider } from "../hooks/pageContext";
import usePages from "../hooks/pages";
import { RekaiTrack } from "../hooks/rekai";
import { getPageThemeColor, getChildren, getSiblings } from "../utils/pageTree";

export default function InfoPageTemplate({ pageContext }) {
  const {
    page: {
      id: pageId,
      databaseId,
      title,
      featuredImage,
      modularityModules,
      content,
      articleHeader: { preambleImage },
      displaySettings: { postSingleShowFeaturedImage },
      modifiedGmt: modifiedDate,
      managedBy: { managedBy },
      contentMedia,
    },
    isPreview,
  } = pageContext;

  const { contentArea, sliderArea } = modularityModules;
  const { processPageContent } = useHTMLProcessor();
  let processedContent = processPageContent(content, { contentMedia });

  const allPages = usePages();

  const childPages = getChildren(allPages, pageId)
    .filter((page) => page.showInMenu)
    .map((page) => ({
      ...page,
      preamble: page.description,
      items: getChildren(allPages, page.id)
        .filter((page) => page.showInMenu)
        .map((page) => ({
          ...page,
        })),
    }));

  const relatedPages = getSiblings(allPages, pageId).map((page) => ({
    label: page.label,
    url: page.uri,
  }));

  const isBreadcrumbsHidden = getBreadcrumbsHidden();

  return (
    <PageContextProvider
      value={{
        ...pageContext,
        postId: databaseId,
        template: "infoPage",
        postTypeName: "page",
      }}
    >
      <Layout
        theme={getPageThemeColor(allPages, pageId)}
        sliderArea={sliderArea}
      >
        <SEO title={title} />
        {!isPreview && <RekaiTrack send={true} />}
        {!isBreadcrumbsHidden && <Breadcrumb />}
        <Article
          title={title}
          preamble={{
            text: processedContent.preamble,
            image: preambleImage,
          }}
          childPages={childPages}
          featuredImage={postSingleShowFeaturedImage && featuredImage}
          // publishedDate={publishedDate}
          modifiedDate={modifiedDate}
          managedBy={managedBy}
          className="o-margin-top-large"
        >
          <div className="o-grid-row">
            <div className="o-grid-block o-grid-block--inherit c-article">
              {processedContent.content}
            </div>
          </div>
          <div className="o-grid-row">
            {contentArea && <ModuleArea area={contentArea} />}
          </div>
        </Article>
        {relatedPages && !!relatedPages.length && (
          <Section>
            <div>
              <div className="o-grid">
                <div className="o-grid-row">
                  <div className="o-grid-block o-grid-block--inherit">
                    <BoxNavigation
                      title="Relaterade sidor"
                      items={relatedPages}
                      className="o-margin-top-medium"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Section>
        )}
      </Layout>
    </PageContextProvider>
  );
}
